import * as React from "react"
import Layout from "../components/layout"


const IndexPage = () => {
  return (
    <Layout>
      <section className="flex flex-col items-center justify-center h-full">
        <h1 className="text-3xl mb-4">
          Philficent.com
        </h1>
        <p>For all the things!.. soon. probably.</p>
      </section>
    </Layout>
  )
}

export default IndexPage
